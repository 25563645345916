/**
 * Created by xinsw on 16/9/9.
 *
 * 设备service封装
 *
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";
import axios from "axios";

/**
 * 获取所有的设备列表
 * @returns {Promise.<*>}
 */
export async function getAllDevices() {
  try {
    return await API.get("device");
  } catch (error) {
    return error;
  }
}

/**
 * 根据关键搜索设备
 * @returns {Promise.<*>}
 */
export async function searchDevicesByKey(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("device", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据关键搜索设备
 * @returns {Promise.<*>}
 */
export async function searchDevicesByCall(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("device/findbyMobile", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 大屏设备
 * @returns {Promise.<*>}
 */
export async function getBigDvc(params, pager = { siez: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("device/bigScreen", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据设备类型搜索设备
 * @returns {Promise.<*>}
 */
export async function searchDevicesByType(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("deviceType", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 更改设备名称
export async function updateDvcName(mac, name) {
  try {
    return await API.post("device/updateName", { mac, name });
  } catch (error) {
    return error;
  }
}

/**
 * 根据key搜索授权设备列表
 * @returns {Promise.<*>}
 */
export async function searchAuthoDvc(
  key,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    //  return await  API.get('deviceAuthorize', {...key, ...pager});
    return await API.get("device", { ...key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据id获取设备类型
 * @returns {Promise.<*>}
 */
export async function getDvcTypeById(id) {
  try {
    return await API.get("deviceType/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 根据id获取授权设备详情
 * @returns {Promise.<*>}
 */
export async function getAuthoDvcById(id) {
  try {
    return await API.get("deviceAuthorize/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 根据mac地址获取设备log
 * @returns {Promise.<*>}
 */
export async function getDvclog(
  id,
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("systemLog/byMac/" + id, { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 删除设备日志记录
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function delDvclog(id) {
  try {
    return await API.del("systemLog/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 设备授权（创建一个设备）
 * @param device Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function createDevice(params) {
  try {
    return await API.post("device/auth", params);
  } catch (error) {
    return error;
  }
}

/**
 * 修改设备授权信息
 * @param  {id:'',sn:'',remarks:''}
 * @returns {Promise.<*>}
 */
export async function changeDvcSn(params) {
  try {
    return await API.post("device/updateSnAndRemarks", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除设备授权
 * @param  {id:'',sn:'',remarks:''}
 * @returns {Promise.<*>}
 */
export async function delDvcAuthorize(macs) {
  try {
    return await API.del("device/" + macs);
  } catch (error) {
    return error;
  }
}

/**
 * 批量导出授权
 * @param device Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function exportAuthorize(key, pager = { size: 9999, page: 1 }) {
  try {
    return await API.get("device/export", { key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 分配授权设备
 */
export async function distribute(device) {
  try {
    return await API.post("deviceAuthorize/updateCustomerProxy", { ...device });
  } catch (error) {
    return error;
  }
}

/**
 * 设备批量授权
 * @param xlsFile Blob excel的二进制文件
 * @returns {Promise.<void>}
 */
export async function multiCreateAuthorize(xlsFile, deviceInfo) {
  try {
    let formData = new FormData();
    formData.append("productTypeId ", deviceInfo.publicNumberproductTypeId);
    formData.append("batch", deviceInfo.batch);
    //formData.append('supplier', deviceInfo.supplier);
    formData.append("filename", xlsFile, xlsFile.name);
    return await API.post("device/import", { formType: "file", formData });
  } catch (error) {
    return error;
  }
}

/**
 * 设备批量分配
 * @param xlsFile Blob excel的二进制文件
 * @returns {Promise.<void>}
 */
export async function multiCreateDistribute(xlsFile, deviceInfo) {
  try {
    let formData = new FormData();
    formData.append("customer", deviceInfo.customer);
    formData.append("filename", xlsFile, xlsFile.name);
    return await API.post("deviceAuthorize/importCustomerProxy", {
      formType: "file",
      formData,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 初始化设备
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function initDvcById(macs) {
  try {
    return await API.del("device/reStart/" + macs);
  } catch (error) {
    return error;
  }
}

/**
 * 设备解绑bymac
 */
export async function unbindDvcByMac(mac) {
  try {
    return await API.post("deviceUser/unBindDeviceByMac", { mac: mac });
  } catch (error) {
    return error;
  }
}

/**
 * 设备解绑bymac + userId
 */
export async function unbindDvcByUser(userId, mac) {
  try {
    return await API.post("deviceUser/unBindDevice", {
      userId: userId,
      mac: mac,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 修改一个设备
 * @param device Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function updateDeviceInfo(device) {
  try {
    return await API.post("device/update", device);
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个设备类型
 * @param device Object {name:'',id:'',deviceTypeCode:''}
 * @returns {Promise.<*>}
 */
export async function createDvcType(device) {
  try {
    return await API.post("deviceType", device);
  } catch (error) {
    return error;
  }
}

/**
 * 更新一个设备类型
 * @param device Object {name:'',id:'',deviceTypeCode:''}
 * @returns {Promise.<*>}
 */
export async function updateDvcType(device) {
  try {
    return await API.post("deviceType/update", device);
  } catch (error) {
    return error;
  }
}

/**
 * 删除一个设备类型
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function delDvcType(id) {
  try {
    return await API.del("deviceType/" + id);
  } catch (error) {
    return error;
  }
}

/*获取本周新增设备*/
export async function getThisWeekAdd() {
  try {
    return await API.get("staticsRecord/thisWeekAddDevice");
  } catch (error) {
    return error;
  }
}

/*根据开始时间和结束时间获取设备的增长数量*/
export async function findDeviceAddByTime(startTime, endTime) {
  try {
    return await API.get("device/dayStaticByTime", { startTime, endTime });
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备分布
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function getDvcLayout(id) {
  try {
    return await API.get("deviceUser/device", { id });
  } catch (error) {
    return error;
  }
}

/* 设备的使用率 */
export async function findDeviceUsePercent() {
  try {
    return await API.get("deviceType/percent");
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有的品牌型号
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function searchProByCustomer(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("productType", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 获取品牌详情
export async function getBrandInfo(id) {
  try {
    return await API.get("productType/" + id);
  } catch (error) {
    return error;
  }
}

// 创建品牌详情
export async function createBrand(params) {
  try {
    return await API.post("productType", params);
  } catch (error) {
    return error;
  }
}

// 更新品牌详情
export async function updateBrand(params) {
  try {
    return await API.post("productType/update", params);
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有的品牌型号 dvcCode
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function getBrandTypeByDvcType(deviceTypeCode) {
  try {
    return await API.get("productType/byDeviceCode", { deviceTypeCode });
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有的品牌型号 PublicNumber
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function searchProByPublicNumber(
  publicNumberId,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("publicNumberProductType/byPublicNumber", {
      publicNumberId,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 创建品牌型号
 * @product: object = {};
 * @returns {Promise.<*>}
 */
export async function createPro(product) {
  try {
    return await API.post("publicNumberProductType", product);
  } catch (error) {
    return error;
  }
}
/**
 * 更新品牌型号
 * @product: object = {};
 * @returns {Promise.<*>}
 */
export async function updateBrandType(product) {
  try {
    return await API.post("publicNumberProductType/update", product);
  } catch (error) {
    return error;
  }
}

/**
 * 通过id获取品牌型号
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function getprodTypeById(id) {
  try {
    return await API.get("publicNumberProductType/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 删除牌型号
 * @id: string;
 * @returns {Promise.<*>}
 */
export async function delbrandType(ids) {
  try {
    return await API.del("productType/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取数据类型，模式类型，滤芯类型
 * @key = [sensor, status, surplus]
 * @returns {Promise.<*>}
 */
export async function getDataPointList(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("dataStreamType", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 获取数据类型，模式类型，滤芯类型
 * @key = [sensor, status, surplus]
 * @returns {Promise.<*>}
 */
export async function dataStream(params, pager = { siez: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("dataStreamType/byType", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建数据类型，模式类型
 * @key = [sensor, status, surplus]
 * @returns {Promise.<*>}
 */
export async function createDataStream(data) {
  try {
    return await API.post("dataStreamType", data);
  } catch (error) {
    return error;
  }
}

/**
 * 更新数据类型，模式类型
 * @key = [sensor, status, surplus]
 * @returns {Promise.<*>}
 */
export async function updateDataStream(data) {
  try {
    return await API.post("dataStreamType/update", data);
  } catch (error) {
    return error;
  }
}

/**
 * 更新数据类型，模式类型
 * @key = [sensor, status, surplus]
 * @returns {Promise.<*>}
 */
export async function delDataStream(ids) {
  try {
    return await API.del("dataStreamType/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取室外信息；
 */
export async function getOutstatus(id) {
  try {
    return await API.get("outPm/" + id);
  } catch (error) {
    return error;
  }
}
/**
 * 滤芯寿命校准
 */
export async function surplusCheck(params) {
  try {
    return await API.get("deviceSurplus/restart/byMac", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除设备
 * @param ids String
 * @returns {Promise.<*>}
 */
export async function removeDevice(macs) {
  try {
    return await API.del("device/" + macs, { macs });
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的信息
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getDeviceById(id) {
  try {
    return await API.get("device/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取appid 列表
 * @param deviceId String 设备id
 * @returns {Promise.<void>}
 */
export async function getAppidByCustomer(customer) {
  try {
    return await API.get("publicNumber/byCustomer", { customer: customer });
  } catch (error) {
    return error;
  }
}

/**
 * 获取滤芯寿命规则列表
 * @param key {}
 * @returns {Promise.<void>}
 */
export async function querySurplusRules(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("surplusRule", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建滤芯寿命规则列表
 * @rule {ruleName:''} 对象等
 * @returns {Promise.<void>}
 */
export async function createSurplusRules(rule) {
  try {
    return await API.post("surplusRule", rule);
  } catch (error) {
    return error;
  }
}

/**
 * 更新滤芯寿命规则列表
 * @rule {ruleName:''} 对象等
 * @returns {Promise.<void>}
 */
export async function updateSurplusRules(rule) {
  try {
    return await API.post("surplusRule/update", rule);
  } catch (error) {
    return error;
  }
}

/**
 * 删除滤芯寿命规则
 * @rule {ruleName:''} 对象等
 * @returns {Promise.<void>}
 */
export async function delSurplusRules(ids) {
  try {
    return await API.del("surplusRule/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取运行模式类型列表
 * @param key {}
 * @returns {Promise.<void>}
 */
// export async function dataStream(key, pager = {siez: PAGE_SIZE, page: 1}) {
//   try {
//     return await  API.get('operationMode', {key, ...pager});
//   } catch (error) {
//     return error;
//   }
// }

/**
 * 更新运行模式类型
 * @rule {ruleName:''} 对象等
 * @returns {Promise.<void>}
 */
export async function updateMode(mode) {
  try {
    return await API.post("operationMode/update", mode);
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的状态
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getDeviceStatusByMac(id) {
  try {
    return await API.get("deviceStatus/byMac/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的传感信息
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getDvcSensorsByMac(mac) {
  try {
    return await API.get("deviceSensor/byMac/" + mac);
  } catch (error) {
    return error;
  }
}
/**
 * 获取设备用户信息
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getDvcUserById(mac) {
  try {
    return await API.get("deviceUser", { mac });
  } catch (error) {
    return error;
  }
}

/**
 * 修改设备管理员
 * @param {id, manageUserId}设备ID,用户Id
 * @returns {Promise.<*>}
 */
export async function changeDvcManager(id, manageUserId) {
  try {
    return await API.post("device/updateManageUser", { id, manageUserId });
  } catch (error) {
    return error;
  }
}

/**
 * 获取传感器一个月的历史纪录
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getMonthSensor(mac, period) {
  try {
    return await API.get("deviceSensor/deviceDayStatic", {
      mac: mac,
      ...period,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取传感器是室外数据历史纪录
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function getOutMonthSensor(cityId, period) {
  try {
    return await API.get("monthWeatherOut/bycityId", {
      cityId: cityId,
      ...period,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 向服务端发送一条刷新设备配置的命令
 * @param id String 设备ID
 * @returns {Promise.<*>}
 */
export async function refreshByDevice(id) {
  try {
    return await API.get("device/refreshByDevice/" + id);
  } catch (error) {
    return error;
  }
}
/**
 * 创建一个设备的状态
 * @param deviceStatus Object 状态对象
 * @returns {Promise.<void>}
 */
export async function createDeviceStatus(deviceStatus) {
  try {
    return await API.post("deviceStatus", deviceStatus);
  } catch (error) {
    return error;
  }
}

/**
 * 更新一个设备的状态
 * @param deviceStatus Object 状态对象
 * @returns {Promise.<void>}
 */
export async function updateDeviceStatus(deviceStatus) {
  try {
    return await API.post("deviceStatus/update", deviceStatus);
  } catch (error) {
    return error;
  }
}

/**
 * 上传设备图片
 * @param {deviceId: '', image: ''} 状态对象
 *
 */
export async function updateDvcImg(param) {
  try {
    return await API.post("device/updateImage", param);
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的操作日志信息
 * @param deviceId String 设备ID
 * @returns {Promise.<*>}
 */
export async function getDeviceOpLogs(
  deviceId,
  pager = { page: 1, size: PAGE_SIZE }
) {
  try {
    return await API.get(
      "teletelecommunicationHistory/device/" + deviceId,
      pager
    );
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的操作日志信息
 * @param deviceId String 设备ID
 * @returns {Promise.<*>}
 */
export async function removeDeviceLog(deviceId) {
  try {
    return await API.del("teletelecommunicationHistory/" + deviceId);
  } catch (error) {
    return error;
  }
}

/**
 * 发送设备的测试指令
 * @param deviceId String 设备ID
 * @returns {Promise.<*>}
 */
export async function sendDeviceTest(deviceId) {
  try {
    return await API.post("device/sendCmd/" + deviceId);
  } catch (error) {
    return error;
  }
}

/**
 * 新建设备的通信配置
 * @param comm Object 通讯配置对象
 * @returns {Promise.<void>}
 */
export async function createDeviceComm(comm) {
  try {
    return await API.post("communication", comm);
  } catch (error) {
    return error;
  }
}

/**
 * 更新设备的通信配置
 * @param comm Object 通讯配置对象
 * @returns {Promise.<void>}
 */
export async function updateDeviceComm(comm) {
  try {
    return await API.post("communication/update", comm);
  } catch (error) {
    return error;
  }
}

/**
 * 获取设备的通信配置
 * @param deviceId String 设备ID
 * @returns {Promise.<void>}
 */
export async function getDeviceCommByDevice(deviceId) {
  try {
    return await API.get("communication/device/" + deviceId);
  } catch (error) {
    return error;
  }
}

export async function batchSendPower(numbers, power) {
  try {
    return await API.get("deviceCmd/power", { numbers, power });
  } catch (error) {
    return error;
  }
}

export async function updateDeviceGroup(params) {
  try {
    return await API.post("device/updateGroupManageId", { ...params });
  } catch (error) {
    return error;
  }
}

export async function updateDeviceSecondGroup(params) {
  try {
    return await API.post("device/updateSecondGroupManageId", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 发送设备的通讯测试指令
 * @param commId String 通讯配置ID
 * @returns {Promise.<*>}
 */
export async function sendDeviceCommTest(commId) {
  try {
    return await API.post("/communication/send/" + commId);
  } catch (error) {
    return error;
  }
}

/***
 * 根据设备关键字搜索带有大类型列表的设备
 * @param key
 * @param pager
 * @returns {Promise.<void>}
 */
export async function searchProvinceListByDeviceKey(
  key,
  deviceSize,
  pager = { page: 1, size: 10 }
) {
  try {
    //http://120.76.222.96:9090/kangzhuo/device/byCompanyAndKey/1/%E5%BA%B7%E5%8D%93
    //source.cancel('搜索的前一个key被覆盖，canel前一个请求，留最新的.');

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (searchResponse) {
      searchResponse.source.cancel();
    }
    searchResponse = API.get(
      "/customer/listProvinceSearchDevice",
      { key, deviceSize, ...pager },
      {
        cancelToken: source.token,
      }
    );
    searchResponse.source = source;
    return await searchResponse;
  } catch (error) {
    return error;
  }
}

let searchResponse = null;
/***
 * 根据客户关键字搜索带有大类型列表的设备
 * @param key
 * @param pager
 * @returns {Promise.<void>}
 */
export async function searchProvinceListByCustomerKey(
  key,
  deviceSize,
  pager = { page: 1, size: 10 }
) {
  try {
    // source.cancel('搜索的前一个key被覆盖，canel前一个请求，留最新的.');

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (searchResponse) {
      searchResponse.source.cancel();
    }
    searchResponse = API.get(
      "/customer/listProvinceSearchCustomer",
      { key, deviceSize, ...pager },
      {
        cancelToken: source.token,
      }
    );
    searchResponse.source = source;
    return await searchResponse;
  } catch (error) {
    return error;
  }
}

export async function exportDeviceDatas(mac, params) {
  try {
    return await API.get(
      "deviceSensor/deviceDayStatic/export",
      { mac, ...params },
      { responseType: "blob" }
    );
  } catch (error) {
    return error;
  }
}

/**
 * 设备定时
 * @param [numbers, power]
 * @returns {Promise.<*>}
 */
export async function setDeviceTime(params) {
  try {
    return await API.get("deviceCmd/setTime", params);
  } catch (error) {
    return error;
  }
}
